<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiInfoPanelProps {
  type: 'danger' | 'success' | 'info' | 'darkgreen';
  text?: string;
  condition?: boolean;
}
const props = withDefaults(defineProps<CiInfoPanelProps>(), {
  text: '',
  condition: false,
});

const styleMapping = {
  danger: 'border-danger bg-[#fef0f0] text-[#dc0911]',
  darkgreen: 'border-dark-green bg-dark-green text-white',
  info: 'border-info bg-white text-[#2276d9]',
  success: 'border-primary bg-[#f9fff4] text-[#3e8e00]',
};
</script>

<template>
  <div
    :class="[styleMapping[props.type], condition ? '' : 'hidden']"
    class="mb-6 rounded-md border px-4 py-3"
  >
    <span v-if="text && text !== ''">{{ text }}</span>
    <slot />
  </div>
</template>

<style></style>
